import React from "react";
import Webcam from "react-webcam";

import * as faceapi from "face-api.js";

const RecognitionCameraComponent = () => {
  const webcamRef = React.useRef(null);
  // const [url, setUrl] = React.useState(null); //single declaration

  //multiple declaration
  const [state, setState] = React.useState({
    url: null,
    image: null,
  });

  React.useEffect(() => {
    const loadModels = async () => {
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
      await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
    };

    loadModels();
  }, []);

  //function to detect face of the user
  const detectFace = async () => {
    console.log("DETECT FACE DETECTION");
    if (webcamRef.current) {
      const detections = await faceapi
        .detectAllFaces(
          webcamRef.current.video,
          new faceapi.TinyFaceDetectorOptions()
        )
        .withFaceLandmarks();

      console.log("detections ****:", detections);

      if (detections.length > 0) {
        capture();
      }
    }
  };

  //function to run face detection
  const runFaceDetection = () => {
    console.log("RUN FACE DETECTION");

    setInterval(() => {
      detectFace();
    }, 3000); // Check for faces every second
  };

  React.useEffect(() => {
    runFaceDetection();
  }, []);

  //setting up camera constraints
  const videoConstraints = {
    width: 540,
    // facingMode: 'user', // For front camera
    facingMode: { exact: "user" }, // Ensure front camera
  };

  //capture button click function
  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Do something with the image
    console.log("CAPTURE IMAGE SS:", imageSrc);
    setState((prevState) => ({
      ...prevState,
      url: imageSrc, // Update only the url field
      image: imageSrc, // Update only the image field
    }));

  //  Submit();
  });

  //user media function
  const onUserMedia = (e) => {
    console.log("onUserMedia log: ", e);
  };

  //user media error function
  const onUserMediaError = (e) => {
    console.log("onUserMediaError log: ", e);
  };

  //submit button click function
  const Submit = React.useCallback(async () => {
    console.log("SUBMIT IMAGE ");
    setState((prevState) => ({
      ...prevState,
      url: null, //update only url field
      image: null //update only image filed
    }));
  });

  return (
    <>
      <h2>HI !!!</h2>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        screenshotQuality="0.92"
        videoConstraints={{ videoConstraints }}
        onUserMedia={onUserMedia}
        onUserMediaError={onUserMediaError}
        width={640} // Adjusted for performance
        height={480} // Adjusted for performance
      />
      {/* <button onClick={capture}>Capture</button> 
     <button
        onClick={() =>
          setState((prevState) => ({
            ...prevState,
            url: null,
            isNextClicked: false,
          }))
        }
      >
        Refresh
      </button>
      
      <button onClick={() => NextImage()}>Next</button>
      {state.isNextClicked && (
        <button
          onClick={
            Submit // Conditionally render the Submit button
          }
        >
          Submit
        </button>
      )}
        */}
      {state.url && (
        <div>
          <img src={state.url} alt="Screenshot" />
        </div>
      )}
    </>
  );
};

export default RecognitionCameraComponent;
