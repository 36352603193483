/* eslint-disable */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import { NavLink, Route, BrowserRouter as Router } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import $ from "jquery";
import CryptoJS from "crypto-js";
import { LoadingPage } from "./LoadingPage";
import ReactDOM from "react-dom";
import emptyCache from "./ClearCache";
import registerServiceWorker from "./registerServiceWorker";
import RegistrationCameraComponent from "./Register Components/Face Register_WebCam";
import RecognitionCameraComponent from "./Register Components/Face Recognition_Webcam";

const VerificationPage = lazy(() => import("./VerificationPage"));
const New_tictoksnav_bar = lazy(() => import("./New_tictoksnav_bar"));
const GstdashboardBasic = lazy(() => import("./GstdashboardBasic"));
const LandingPage = lazy(() => import("./LandingPage"));
const FooterText = lazy(() => import("./FooterText"));
const GstdashboardPremium = lazy(() => import("./GstdashboardPremium"));
const SignIn = lazy(() => import("./SignIn"));
const SignUp = lazy(() => import("./SignUp"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const Downloadpage = lazy(() => import("./Downloadpage"));
const ResetPasswordLogin = lazy(() => import("./ResetPasswordLogin"));

const QRCodeScanner = lazy(() => import("./QRCode_Attendance/QRCode Scanner"));
const QRCodeGenerator = lazy(() => import("./QRCode_Attendance/QRCode Generator"));
const OTPVerification = lazy(() => import("./QRCode_Attendance/Verfication/OTPVerfication"));

var divStyle = {
  color: "white",
  backgroundcolor: "green",
};

//Get the latitude and the longitude;
function successFunction(position) {
  var lat = position.coords.latitude;
  var lng = position.coords.longitude;
  //console.log("succ",position)
  codeLatLng(lat, lng);
}

function errorFunction() {
  //alert("Geocoder failed");
}

function codeLatLng(lat, lng) {
  $.ajax({
    type: "GET",
    url:
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lng +
      "&sensor=true",
    success: function(data) {
      //var loca=data.results[0].address_components[1].long_name +","+ data.results[0].address_components[2].long_name;
      localStorage.setItem("Location", "-");

      //  console.log("full",data,"status",data.status)
      if (data.status == "OK") {
        //console.log("full address",data.results[0].formatted_address);
        //console.log("main Address",data.results[0].address_components[1].long_name,",",data.results[0].address_components[2].long_name);

        var loca =
          data.results[0].address_components[1].long_name +
          "," +
          data.results[0].address_components[2].long_name;
        localStorage.setItem("Location", loca);
      }
    },
  });
}
/*LAST EDITED ON 2018-04-21 BY B.Priyanka
CHANGES MADE : EDITED THE URL FOR GIVING DEMO TO THE CLIENT
			 :   MADE THE URL NOT TO LOAD WITH CREDENTIALS
*/
/*
ReactDOM.render(<TableToExcel / > , document.getElementById("root"));
registerServiceWorker();
*/
try {
 // alert("try")
  if (localStorage.getItem("isLoggedIn")) {
 //   alert("IS LOGIN");
    var login = CryptoJS.AES.decrypt(
      localStorage.getItem("isLoggedIn"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
  //  alert("LOGIN STATUS: "+login);

   var orgAttendanceType = CryptoJS.AES.decrypt(
    localStorage.getItem("OrgAttendanceType"),
    "shinchanbaby"
  ).toString(CryptoJS.enc.Utf8);
  
  var role = CryptoJS.AES.decrypt(
    localStorage.getItem("Role"),
    "shinchanbaby"
  ).toString(CryptoJS.enc.Utf8);

  var QROTP_Status = CryptoJS.AES.decrypt(
    localStorage.getItem("QROTP_Status"),
    "shinchanbaby"
  ).toString(CryptoJS.enc.Utf8);

  
    if (login == "true") {
      if (navigator.onLine) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            successFunction,
            errorFunction
          );
        } else {
          //alert("Geolocation is not Supported by this Browser");
        }
      }
      window.scrollTo(0, 0);
      var planName = CryptoJS.AES.decrypt(
        localStorage.getItem("PlanName"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8);
      //console.log("plantype", data.planName);
      //alert("plantype"+planName);
      if (planName.toLowerCase() == "basic") {
        console.log("basicplan", planName);
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingPage />}>
              <div>
                <Route path="/" component={GstdashboardBasic} />
                {/* <Route exact path="/Attendence" component={Attendence} />
										<Route exact path="/Charts" component={Charts} />
										<Route exact path="/AttendanceDisplay" component={AttendanceDisplay} />
										<Route exact path="/AddEmployee" component={AddEmployee} />
										<Route exact path="/SearchEmployee" component={SearchEmployee} />
										<Route exact path="/UpdateEmployee" component={UpdateEmployee} />
										<Route exact path="/EmployeeAttendanceRequest*" component={EmployeeRequestAcceptReject} />
										<Route exact path="/EmployeeLeaveRequest*" component={EmployeeRequestAcceptReject} />
										<Route exact path="/LoginIssuePage*" component={LoginIssuePage} />
                                        <Route exact path="/DeviceIssuePage*" component={DeviceIssuePage} />
                  						<Route exact path="/CheckinIssuePage*" component={CheckinIssuePage} />
                  						<Route exact path="/TicketReopenPage*" component={TicketReopenPage} />
										<Route exact path="/PaymentHistory*" component={PaymentHistory} />
 						*/}{" "}
                <Route
                  exact
                  path="/ForgotPassword"
                  component={ForgotPassword}
                />
                <Route path="/" component={FooterText} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("root")
        );
        registerServiceWorker();
      } else if (planName.toLowerCase() == "premium") {
        console.log("premiumplan", planName);
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingPage />}>
              <div>
                <Route path="/" component={GstdashboardPremium} />
                {/* 	<Route exact path="/Attendence" component={Attendence} />
										<Route exact path="/Charts" component={Charts} />
										<Route exact path="/AttendanceDisplay" component={AttendanceDisplay} />
		
										<Route exact path="/AddEmployee" component={AddEmployee} />
										<Route exact path="/SearchEmployee" component={SearchEmployee} />
										<Route exact path="/UpdateEmployee" component={UpdateEmployee} />
										<Route exact path="/EmployeeAttendanceRequest*" component={EmployeeRequestAcceptReject} />
										<Route exact path="/EmployeeLeaveRequest*" component={EmployeeRequestAcceptReject} />
										<Route exact path="/LoginIssuePage*" component={LoginIssuePage} />
                                        <Route exact path="/DeviceIssuePage*" component={DeviceIssuePage} />
                  						<Route exact path="/CheckinIssuePage*" component={CheckinIssuePage} />
                  						<Route exact path="/TicketReopenPage*" component={TicketReopenPage} />
										  <Route exact path="/PaymentHistory*" component={PaymentHistory} />
									 */}{" "}
                <Route
                  exact
                  path="/ForgotPassword"
                  component={ForgotPassword}
                />
                <Route path="/" component={FooterText} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("root")
        );
        registerServiceWorker();
      } else if (planName.toLowerCase() == "elite") {
        console.log("eliteplan", planName.toLowerCase());

        if (role == "QR_SCANNER") {
        //  alert("SCANNER");

          ReactDOM.render(
              <Router>
                <Suspense fallback={<LoadingPage />}>
                  <div>
                    <Route path="/" component={QRCodeScanner} />
                  </div>
                </Suspense>
              </Router>,
              document.getElementById("root")
            );
            

        } else if (orgAttendanceType == "QrCode" && role!="Director" && role!="TAPropertier" && QROTP_Status=="OTP_Verified") {
        //  alert("org type qr");
          ReactDOM.render(
              <Router>
                <Suspense fallback={<LoadingPage />}>
                  <div>
                    <Route path="/" component={QRCodeGenerator} />
                  </div>
                </Suspense>
              </Router>,
              document.getElementById("root")
            );
            
        } else if (orgAttendanceType == "QrCode" && role!="Director" && role!="TAPropertier" && QROTP_Status!="OTP_Verified") {
          //  alert("org type qr");
            ReactDOM.render(
                <Router>
                  <Suspense fallback={<LoadingPage />}>
                    <div>
                      <Route path="/" component={OTPVerification} />
                    </div>
                  </Suspense>
                </Router>,
                document.getElementById("root")
              );
              
          } else {
        //  alert("not scanner & qrcode");
          console.log("eliteplan", planName.toLowerCase());

          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingPage />}>
                <div>
                  <Route
                    path="/"
                    component={New_tictoksnav_bar}
                  />
                  {/* 
                          <Route exact path="/" component={GstdashboardElite} />
                          <Route exact path="/Attendence" component={Attendence} />
                          <Route exact path="/Charts" component={Charts} />
                          <Route exact path="/AttendanceDisplay" component={AttendanceDisplay} />
                          <Route exact path="/AddEmployee" component={AddEmployee} />
                          <Route exact path="/SearchEmployee" component={SearchEmployee} />
                          <Route exact path="/UpdateEmployee" component={UpdateEmployee} />
                          <Route exact path="/EmployeeAttendanceRequest/*" component={EmployeeRequestAcceptReject} />
                          <Route exact path="/EmployeeLeaveRequest/*" component={EmployeeRequestAcceptReject} />
                          <Route exact path="/LoginIssuePage/*" component={LoginIssuePage} />
                          <Route exact path="/DeviceIssuePage/*" component={DeviceIssuePage} />
                          <Route exact path="/CheckinIssuePage/*" component={CheckinIssuePage} />
                          <Route exact path="/TicketReopenPage/*" component={TicketReopenPage} />
                      */}{" "}
                  <Route
                    exact
                    path="/ForgotPassword"
                    component={ForgotPassword}
                  />
                  <Route path="/" component={FooterText} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("root")
          );
          registerServiceWorker();
        }
      }
    } else {
      if (window.location.pathname.toLowerCase() === "/signup") {
        window.history.replaceState({}, document.title, "/Signup");
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingPage />}>
              <div>
                <Route path="/" component={SignUp} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("root")
        );
        registerServiceWorker();
      } else if (window.location.pathname.toLowerCase() === "/Signin") {
        window.history.replaceState({}, document.title, "/SignIn");
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingPage />}>
              <div>
                <Route path="/" component={SignIn} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("root")
        );
        registerServiceWorker();
      } else {
      //  alert("THE ELSE PART");
        localStorage.clear();
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingPage />}>
              <div>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/Dashboard*" component={SignIn} />
                <Route
                  exact
                  path="/VerificationPage*"
                  component={VerificationPage}
                />
                <Route exact path="/VerifyCode*" component={VerificationPage} />
                <Route
                  exact
                  path="/VerifyPassword*"
                  component={ResetPasswordLogin}
                />
                <Route exact path="/DownloadApps*" component={Downloadpage} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("root")
        );
        registerServiceWorker();
      }
    }
  } else {
  //  alert("!*************IS LOGIN");
    console.log("path name ", window.location.pathname);
    if (window.location.pathname.toLowerCase() === "/signup") {
      window.history.replaceState({}, document.title, "/Signup");
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingPage />}>
            <div>
              <Route path="/" component={SignUp} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("root")
      );
      registerServiceWorker();
    } else if (window.location.pathname.toLowerCase() === "/signin") {
      window.history.replaceState({}, document.title, "/SignIn");
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingPage />}>
            <div>
              <Route path="/" component={SignIn} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("root")
      );
      registerServiceWorker();
    } else {
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingPage />}>
            <div>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/Dashboard*" component={SignIn} />
              <Route
                exact
                path="/VerificationPage*"
                component={VerificationPage}
              />
              <Route exact path="/VerifyCode*" component={VerificationPage} />
              <Route
                exact
                path="/VerifyPassword*"
                component={ResetPasswordLogin}
              />
              <Route exact path="/DownloadApps*" component={Downloadpage} />
              <Route
                path="/RemoteAttendanceDateWise*"
                component={LandingPage}
              />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("root")
      );
      registerServiceWorker();
    }
  }
} catch (error) {
 // alert("EMPTY PART error")
  console.log("Exception in page ", error);
 
  if (!localStorage.getItem("EmptyCache")) {
    localStorage.clear();
    emptyCache();
  }
  ReactDOM.render(
    <Router>
      <Suspense fallback={<LoadingPage />}>
        <div>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/Dashboard*" component={SignIn} />
          <Route exact path="/VerificationPage*" component={VerificationPage} />
          <Route exact path="/VerifyCode*" component={VerificationPage} />
          <Route exact path="/VerifyPassword*" component={ResetPasswordLogin} />
          <Route exact path="/DownloadApps*" component={Downloadpage} />
          <Route
            exact
            path="/RemoteAttendanceDateWise*"
            component={LandingPage}
          />
        </div>
      </Suspense>
    </Router>,
    document.getElementById("root")
  );
  registerServiceWorker();
  localStorage.setItem("EmptyCache", true);
 
}
