import React from "react";
import Webcam from "react-webcam";
import {
  createCollection,
  RegisterFace,
  registerFace,
  uploadImage,
} from "./RegisterFace";
import {
  createRekognitionClient,
  createS3_Client,
} from "./AWS RekognitionAPI Functions";
import * as faceapi from "face-api.js";
import { ListBucketsCommand } from "@aws-sdk/client-s3";

const bucketName = "AWS_Rekognition_Image_Collection";
const imageName = "TestImage";
const imageFile = "";
const collectionId = "";

const region = "ap-south-1";
const accessKeyId = "AKIAV74U5CX74AOPWSNA";
const secretAccessKey = "SBnISLMKFCtIe+48XzLdG4mIDpsgPCsJdZPereyO";

// Initialize the S3 client
const s3_Client = createS3_Client(region, accessKeyId, secretAccessKey); // Replace with your region

// Create a Rekognition client
const client = createRekognitionClient(region, accessKeyId, secretAccessKey); // Pass the dynamic values as parameters

const RegistrationCameraComponent = () => {
  const webcamRef = React.useRef(null);
  // const [url, setUrl] = React.useState(null); //single declaration

  //multiple declaration
  const [state, setState] = React.useState({
    url: null,
    image1: null,
    image2: null,
    isNextClicked: false,
    isFaceFound: false,
  });

  //setting up camera constraints
  const videoConstraints = {
    width: 540,
    // facingMode: 'user', // For front camera
    facingMode: { exact: "user" }, // Ensure front camera
  };

  //function to detect face of the user
  const detectFace = async () => {
    console.log("DETECT FACE DETECTION");
    if (webcamRef.current) {
      const detections = await faceapi
        .detectAllFaces(
          webcamRef.current.video,
          new faceapi.TinyFaceDetectorOptions()
        )
        .withFaceLandmarks();

      console.log("detections ****:", detections);

      if (detections.length > 0) {
        setState((prevState) => ({
          ...prevState,
          isFaceFound: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isFaceFound: false,
        }));
      }
    }
  };

  //capture button click function
  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Do something with the image
    console.log("CAPTURE IMAGE SS:", imageSrc);
    setState((prevState) => ({
      ...prevState,
      url: imageSrc, // Update only the url field
    }));

    if (state.image1 == null) {
      setState((prevState) => ({
        ...prevState,
        image1: imageSrc, // Update only the image1 field
      }));
    } else {
      //FIRST IMAGE CAPTURED
      setState((prevState) => ({
        ...prevState,
        image2: imageSrc, // Update only the image2 field
      }));
    }
    setState((prevState) => ({
      ...prevState,
      isFaceFound: false,
    }));
  });

  //user media function
  const onUserMedia = (e) => {
    console.log("onUserMedia log: ", e);
  };

  //user media error function
  const onUserMediaError = (e) => {
    console.log("onUserMediaError log: ", e);
  };

  //next button click function
  const NextImage = React.useCallback(async () => {
    console.log("NEXT IMAGE BUTTON");
    setState((prevState) => ({
      ...prevState,
      url: null,
      isNextClicked: true,
      isFaceFound: false,
    }));
  });

  //submit button click function
  const Submit = React.useCallback(async () => {
    console.log("SUBMIT IMAGE ");
    var s3_Client = createS3_Client(region, accessKeyId, secretAccessKey); // Replace with your region

    try {
      // Check S3 connection by listing the buckets or accessing the specific bucket
      await s3_Client.send(new ListBucketsCommand({}));
      console.log("Connected to S3 successfully.");

      await RegisterFace(
        s3_Client,
        bucketName,
        imageName,
        imageFile,
        client,
        collectionId
      );
      console.log("Image uploaded and face registered successfully.");
    } catch (error) {
      console.error("An error occurred during the process:", error);
      if (error.name === "InvalidAccessKeyId") {
        console.error("Invalid AWS credentials.");
      } else if (error.name === "NoSuchBucket") {
        console.error("Bucket does not exist.");
      } else {
        console.error("Unknown error: ", error.message);
      }
    }
    setState((prevState) => ({
      ...prevState,
      url: null,
      isNextClicked: false,
      isFaceFound: false,
    }));
  });

  //function to run face detection
  const runFaceDetection = () => {
    console.log("RUN FACE DETECTION");

    setInterval(() => {
      detectFace();
    }, 3000); // Check for faces every 3 second
  };

  React.useEffect(() => {
    const loadModels = async () => {
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
      await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
    };

    loadModels();

    runFaceDetection();
  }, []);

  return (
    <>
      <h2>HI !!!</h2>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        screenshotQuality="0.92"
        videoConstraints={{ videoConstraints }}
        onUserMedia={onUserMedia}
        onUserMediaError={onUserMediaError}
      />
      {state.isFaceFound && <h5 style={{ color: "green" }}>Face Found!! </h5>}
      <button onClick={capture}>Capture</button>
      <button
        onClick={() =>
          setState((prevState) => ({
            ...prevState,
            url: null,
            isNextClicked: false,
          }))
        }
      >
        Refresh
      </button>
      <button onClick={() => NextImage()}>Next</button>
      {state.isNextClicked && (
        <button
          onClick={
            Submit // Conditionally render the Submit button
          }
        >
          Submit
        </button>
      )}
      {state.url && (
        <div>
          <img src={state.url} alt="Screenshot" />
        </div>
      )}
    </>
  );
};

export default RegistrationCameraComponent;
